.Albums form {
    padding-bottom: 15px;
  }
  
  .Albums form textarea {
    height: 300px;
    font-size: 24px;
  }
  
  .Albums LoaderButton {
    width: 20px;
    
  }
  .Albums input {
    width: 50%;
    
  }

 